import React from "react";
import PaginationDot from "./PaginationDot";

const styles = {
  root: {
    position: "absolute",
    bottom: -16,
    right:"50%",
    display: "flex",
    flexDirection: "row"
  }
};
/**
 *
 * @param index {number} - current active index
 * @param dots {number} - number of dots
 * @param onChangeIndex {function} - index change handler
 * @returns {*}
 * @constructor
 */
export const Pagination = ({ index, dots, onChangeIndex }) => {
  const handleClick = (event, index) => {
    onChangeIndex(index);
  };

  const children = [];
  for (let i = 0; i < dots; i += 1) {
    children.push(<PaginationDot key={i} index={i} active={i === index} onClick={handleClick} />);
  }

  return <div style={styles.root}>{children}</div>;
};

export default Pagination;
