import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  sectionHeader: {
    marginBottom: theme.spacing(8),
    textAlign: "center",
    position: "relative"
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: "uppercase",
    position: "relative",
    color: theme.palette.text.primary,
    "&::before": {
      content: '" "',
      position: "absolute",
      bottom: "-8px",
      width: "80px",
      height: "2px",
      background: "#8e44ad",
      transition: "0.3s"
    }
  },
  shadow: {
    fontSize: theme.typography.h2.fontSize,
    color: "rgba(0, 0, 0, 0.07)",
    zIndex: 2,
    fontWeight: 500,
    textTransform: "capitalize",
    position: "absolute",
    top: "-8px",
    left: 0,
    width: "100%"
  }
}));

export default ({ titleText, shadowText }) => {
  const classes = useStyles();
  return (
    <div className={classes.sectionHeader}>
      <h2 className={classes.title}>{titleText}</h2>
      <span className={classes.shadow}>{shadowText}</span>
    </div>
  );
};
