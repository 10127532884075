import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { styled } from "@material-ui/core/styles";


const Content = styled("p")({
  fontSize: "40px",
  lineHeight: "54px",
  textAlign: "center",
  fontFamily: "\"OpenSans\", sans-serif",
  color: "#546e7a",
  fontWeight: "400"
});

export default function Home() {
  return (
    <Section id="home" backgroundColor={"transparent"}>
      <SectionHeader shadowText="About us" titleText="About us" />
      <Content>Whether you are a startup or an established company, we develop and deliver products that scale to your
        needs</Content>
    </Section>
  );
}
