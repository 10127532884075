import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles(theme => ({
  media: {
    width:"100%",
    height: "80px",
    [theme.breakpoints.down("xs")]:{
      height:"100%"
    }
  },
  card: {
    display: "flex",
    justifyContent:"center",
    alignContent: "center",
    padding: theme.spacing(2),
    transition: "0.3s",
    width: "100%",
    boxShadow: "none",
    backgroundColor: "transparent",
    borderRadius: 0,
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: "0 2px 12px 0 rgba(84, 110, 122, 0.35)"
    },
    [theme.breakpoints.down("xs")]:{
      padding: theme.spacing(2),
    }
  }
}));
/**
 *
 * @param src - image src
 * @param alt - image alt
 * @param title - image title
 * @returns {*}
 */
export default (props = {}) => {
  const { src, alt, title } = props;
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <img src={src} alt={alt} title={title} className={classes.media} />
    </Card>
  );
};
