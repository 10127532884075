import React from "react";
import _ from "lodash";
import SwipeableViews from "react-swipeable-views";
import ReferenceItem from "./ReferenceItem";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Content from "./commons/Content";
import Pagination from "./commons/Pagination";
import { autoPlay } from "react-swipeable-views-utils";
import referencesList from "../constants/references-list";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const References = () => {
  const [index, setActiveStep] = React.useState(0);
  const maxSteps = referencesList.length;

  const handleChangeIndex = index => {
    setActiveStep(index);
  };
  const swipeableViewChildren = _.map(referencesList, r => <ReferenceItem {...r} />);

  return (
    <Section id="references" backgroundColor="transparent">
      <SectionHeader shadowText="Our Customers" titleText="Customer testimonials" />
      <Content>We take pride and energy from the satisfaction our customers express.</Content>
      <div style={{ position: "relative" }}>
        <AutoPlaySwipeableViews
          index={index}
          onChangeIndex={handleChangeIndex}
          children={swipeableViewChildren}
          interval={10000}
        />
        <Pagination dots={maxSteps} index={index} onChangeIndex={handleChangeIndex} />
      </div>
    </Section>
  );
};

export default References;
