import React from "react";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root:{
    [theme.breakpoints.down("xs")]:{
      flexDirection: "column",
      alignItems:"center"
    }
  },
  logo: {
    flexBasis:"15%",
    paddingRight: theme.spacing(4),
    flexGrow:0,
    [theme.breakpoints.down("xs")]:{

    }
  },
  text:{
    flexBasis:"85%",
  },
  inline: {
    display: "inline"
  }
}));

const ReferenceItem = ({ name, role, description, website, logo, businessName }) => {
  const classes = useStyles();
  return (
    <ListItem alignItems="flex-start" className={classes.root}>
      <ListItemAvatar>
        <Link href={website} target="_blank" rel="noopener">
          <img className={classes.logo} src={logo} alt={businessName}/>
        </Link>
      </ListItemAvatar>
      <ListItemText
        className={classes.text}
        primary={description}
        primaryTypographyProps={{
          paddingbottom:"16px"
        }}
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              {name}
            </Typography>
            {` - ${role}`}
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

export default ReferenceItem;
