import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";
import { Link } from "react-scroll";

const useStyles = makeStyles(theme => ({
  tab: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    color: "black",
    "&:hover": {
      color: "#8e44ad",
      opacity: 1
    },
    "&:selected": {
      color: "#8e44ad",
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: "#8e44ad"
    }
  },
  root: {
    flexGrow: 1
  },
  typography: {
    padding: theme.spacing(3)
  },
  demo1: {
    backgroundColor: theme.palette.background.paper
  },
  demo2: {
    backgroundColor: "#2e1534",
    justifyContent: "end"
  }
}));

const defaultMenuProps = {
  offset: -100,
  smooth: true,
  duration: 500,
  component: Link,
  disableRipple: true
};

const menuItems = [
  { ...defaultMenuProps, label: "Home", to: "home" },
  {
    ...defaultMenuProps,
    label: "Services",
    to: "services"
  },
  {
    ...defaultMenuProps,
    label:"Customers",
    to:"references"
  },
  { ...defaultMenuProps, label: "Technologies", to: "technologies" },
  { ...defaultMenuProps, label: "Team", to: "team" },
  {
    ...defaultMenuProps,
    label: "Contact",
    to: "contact"
  }
];

export default function CustomizedTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <Tabs value={value} onChange={handleChange}>
          {menuItems.map(miProps => (
            <Tab key={miProps.label} className={classes.tab} {...miProps} />
          ))}
        </Tabs>
      </div>
    </div>
  );
}
