import React from "react";
import ReactDOM from "react-dom";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import theme from "./styles/theme";

import TopNavBar from "./components/TopNavBar";
import Home from "./components/Home";
import Technologies from "./components/Technologies";
import References from "./components/References";
import Services from "./components/Services";
import Team from "./components/Team";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

import * as serviceWorker from "./serviceWorker";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <TopNavBar />
      <div>
        <Home />
        <Services />
        <References />
        <Technologies />
        <Team />
        <Contact />
        <Footer />
      </div>
    </ThemeProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.register();
