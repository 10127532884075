export function checkHttpStatus(response, errorMsg) {
  if (response.ok) {
    return Promise.resolve(response)
  }

  return response.json()
    .then((json) => {
      var responseText = (typeof errorMsg === 'undefined' ? json.message || response.statusText : errorMsg)
      var error = new Error(response.status + ' : ' + responseText)
      return Promise.reject(Object.assign(error, {
        response,
        json,
        status: response.status
      }))
    })
}

export function parseJSON(response) {
  // for some reason either .then is called twice or the promise is already resolved here in some cases, so checking if body was already used is the fix
  if (response.bodyUsed === true) {
    return response;
  } else {
    if (response.status === 204 || response.statusText === 'No Content') {
      return {};
    }
    return response.json();
  }
}
