export const servicesList = [
  {
    name: "Web Development",
    icon: "cog",
    backgroundColor: "#e6f8ed",
    color:"#57d283"
  },
  {
    name: "Mobile App Development",
    icon:"tab",
    backgroundColor: "#e7e6ff",
    color:"#7271ff"
  },
  {
    name:"Cloud Native Applications",
    icon: "cloudnetwork",
    backgroundColor:"#f3ead6",
    color: "#f1a90a"
  },
  {
    name: "Migrate to Cloud",
    icon: "cloud-upload",
    backgroundColor:"#e3eafa",
    color:"#457bf4"
  }
]

export default servicesList