import React from "react";
import { styled } from "@material-ui/core/styles";
import { palette, spacing, compose } from "@material-ui/system";
import LineIcon from "react-lineicons";

const Logo = styled("div")(
  compose(
    spacing,
    palette
  )
);

export default function OurLogo({color="purple",iconSize="sm", bgColor="paper"}) {
  return (
    <Logo color={color} bgcolor={bgColor} p={1}>
      <LineIcon name={"code-alt"} size={iconSize} />
      &nbsp;&nbsp;cleanCodeWorks
    </Logo>
  );
}
