export const referencesList = [
  {
    businessName:"Knowledgeflow",
    name: "Wouter Van den Bosch",
    role:"Knowledgeflow Founder",
    description:
      "The CleanCodeWorks team played a critical role in developing, deploying and supporting our SaaS platform. Their professionalism was apparent, not only in the quality of their code, but also in the way they contributed to a better underlying architecture design. Their experience with DevOps, Test Driven Development and Continuous Integration helped to ensure the long-term stability of our platform.",
    website: "https://www.knowledgeflow.io",
    logo:"/img/clients/KnowledgeflowLogo.png"
  },
  {
    businessName: "imec",
    name:"Tanguy Coenen",
    role:"Program manager at imec",
    description:"CleanCodeWorks stepped in and delivered amazingly fast the integration of a web visualisation app with our latest portable EEG hardware.",
    website:"https://www.imec-int.com",
    logo:"/img/clients/LOGO-IMEC_black-small.svg.png"
  }
];

export default referencesList;
