import React from "react";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import useForm from "../hooks/useForm";
import contactFormSchema from "../schema/contactform";
import { checkHttpStatus, parseJSON } from "../utils/fetch";

const CssTextField = withStyles({
  root: {
    width: "100%",
    color: "#8e44ad",
    lineHeight: "24px",
    fontSize: "16px",
    "& label": {
      top: 0,
      color: "#8e44ad"
    },
    "& label.Mui-focused": {
      color: "#8e44ad"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green"
    },
    "& .MuiInputBase-root": {
      color: "#8e44ad"
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#f5f5f5",
      borderRadius: 30,
      "& fieldset": {
        borderRadius: 30,
        borderColor: "transparent",
        color: "#8e44ad"
      },
      "&:hover fieldset": {
        borderColor: "transparent",
        color: "#8e44ad"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#8e44ad",
        borderWidth: "1px",
        color: "#8e44ad",
        "&.legend": {
          backgroundColor: "white"
        }
      }
    }
  }
})(TextField);

const useStyles = makeStyles(theme => ({
  submit: {
    transition: "0.2s",
    width: "100%",
    borderRadius: 30,
    color: "#8e44ad",
    backgroundColor: "#f5f5f5",
    "&:hover": {
      color: "white",
      backgroundColor: "#8e44ad"
    }
  }
}));

const initialValues = { name: "", email: "", msg_subject: "", message: "" };

export default props => {
  const defaultFetchState = {
    resolved: false,
    fetching: false,
    error: null,
    data: null
  };
  const [submitState, setFetchState] = React.useReducer((s, a) => ({ ...s, ...a }), {});

  const onSubmit = async values => {
    setFetchState({
      ...defaultFetchState,
      fetching: true
    });
    try {
      let data = await window
        .fetch("/contactform", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(values)
        })
        .then(checkHttpStatus)
        .then(parseJSON);
      setFetchState({ ...defaultFetchState, resolved: true, data });
    } catch (error) {
      setFetchState({ ...defaultFetchState, resolved: true, error });
    }
  };

  const { values, errors, handleChange, handleSubmit } = useForm({
    onSubmit,
    initialValues,
    schema: contactFormSchema
  });
  const classnames = useStyles();
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <CssTextField
            error={!!errors.name}
            helperText={errors.name}
            required
            onChange={handleChange}
            label="Your name"
            variant="outlined"
            id="name"
            name="name"
            value={values.name}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <CssTextField
            error={!!errors.email}
            helperText={errors.email}
            required
            label="Your email"
            variant="outlined"
            id="email"
            name="email"
            onChange={handleChange}
            value={values.email}
          />
        </Grid>
        <Grid item xs={12}>
          <CssTextField
            error={!!errors.msg_subject}
            helperText={errors.msg_subject}
            required
            value={values.msg_subject}
            label="Subject"
            name="msg_subject"
            variant="outlined"
            id="msg_subject"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <CssTextField
            error={!!errors.message}
            helperText={errors.message}
            label="Your message"
            required
            variant="outlined"
            placeholder="Your message"
            id="message"
            name="message"
            rows={10}
            rowsMax={10}
            multiline
            value={values.message}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <Button disabled={!_.isEmpty(errors) || submitState.resolved} type="submit" className={classnames.submit}>
            Send
          </Button>
        </Grid>
        <Grid item xs={12}>
          {submitState.fetching ? "Submitting..." : ""}
          {submitState.resolved && !_.isEmpty(submitState.data) ? "Thank you" : ""}
          {submitState.resolved && !_.isEmpty(submitState.error) ? _.get(submitState.error,"json.message",submitState.error.message) : ""}
        </Grid>
      </Grid>
    </form>
  );
};
