import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import  Grid from "@material-ui/core/Grid";
import ServiceItem from "./ServiceItem";
import servicesList from "../constants/services-list";
import SectionHeader from "./SectionHeader";
import Section from "./Section";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }
}));

export default function Services() {
  const classes = useStyles();
  return (
    <Section id="services">
      <SectionHeader shadowText="Services" titleText="Our Services" />
      <Grid container spacing={3} className={classes.root}>
        {servicesList.map((si, key) => {
          return (
            <Grid item lg={4} md={6} sm={6} xs={12} key={key}>
              <ServiceItem {...si} />
            </Grid>
          );
        })}
      </Grid>
    </Section>
  );
}
