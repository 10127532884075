import React from "react";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import ContactForm from "./ContactForm"
export default props => {
  return (
    <Section id="contact">
      <SectionHeader shadowText="Contact" titleText="Get In Touch"/>
      <ContactForm/>
    </Section>
  );
};
