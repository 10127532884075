import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  section: {
    padding: theme.spacing(4, 3, 6),
    textAlign: "center",
    verticalAlign: "middle",
    width: "100vw",
    zIndex: 2,
    position: "relative",
    top: "50px",
    backgroundColor: ({ backgroundColor }) => backgroundColor || theme.palette.common.white
  },
  contentWrapper: {
    maxWidth: "1020px",
    marginRight: "auto",
    marginLeft: "auto",
    padding: 0,
  }
}));

export default ({ backgroundColor, children, id }) => {
  const classes = useStyles({ backgroundColor });
  return (
    <div id={id} className={classes.section}>
      <div className={classes.contentWrapper}>{children}</div>
    </div>
  );
};
