import React from "react";
import { Grid, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";

const useStyles = makeStyles(theme => ({
  side: {
    textAlign: "left"
  }
}));

export default props => {
  const classes = useStyles(props);
  return (
    <Section>
      <Grid container spacing={10}>
        <Grid item xs={6} className={classes.side}>
          <ListItemText
            primaryTypographyProps={{
              variant: "h6"
            }}
            secondaryTypographyProps={{ variant: "body1" }}
            primary={"cleanCodeWorks"}
            secondary={
              "Reach out to us if you're looking for a cohesive team with years of experience in building and delivering robust solutions"
            }
          />
        </Grid>
        <Grid item xs={6} className={classes.side}>
          <ListItemText
            primaryTypographyProps={{ variant: "h6" }}
            secondaryTypographyProps={{ variant: "body1" }}
            primary={"Write us"}
            secondary={"Address: Strada Corneliu Coposu, nr. 2, ap. 43, Satu Mare, Romania"}
          />
        </Grid>
        <Grid item xs={12}>
          <ListItemText secondary={`@${new Date().getFullYear()} by CleanCodeWorks`} />
        </Grid>
      </Grid>
    </Section>
  );
};
