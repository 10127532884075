import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LineIcon from "react-lineicons";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles(theme => ({
  name: {
    fontSize: "18px"
  },
  card: {
    paddingTop: theme.spacing(2),
    border: props => `1px solid ${props.backgroundColor}`,
    borderRadius: 0,
    transition: "0.3s",
    width: "100%",
    boxShadow: "none",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: props => `0 2px 12px 0 ${props.backgroundColor}`
    }
  },
  iconWrapper: {
    display: "inline-flex",
    borderRadius: "50%",
    padding: theme.spacing(1),
    backgroundColor: props => props.backgroundColor || theme.palette.primary.main
  }
}));
export default ({ name, icon, backgroundColor, color }) => {
  const classes = useStyles({ backgroundColor });

  return (
    <Card className={classes.card}>
      <div className={classes.iconWrapper}>
        <LineIcon name={icon} size={"sm"} style={{ color }} />
      </div>
      <h4 className={classes.name}>{name}</h4>
    </Card>
  );
};
