import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  typography: {
    fontFamily: `"Montserrat", sans-serif`
  },
  overrides: {
    MuiToolbar: {
      root: {
        backgroundColor: "white",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#8e44ad"
      },
      flexContainer: {
        padding: "1px 10px 1px 10px",
        display: "flex",
        width: "100%",
        flexWrap: "nowrap",
        flexDirection: "row",
        marginRight: "auto!important",
        justifyContent: "flex-end!important"
      }
    },
    MuiTab: {
      root: {
        background: "paper"
      }
    }
  }
});

export default theme;
