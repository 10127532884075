import _ from "lodash";
import { useState } from "react";

/**
 *
 * @param onSubmit
 * @param initialValues
 * @param schema - joi schema
 * @returns {{handleSubmit: *, handleChange: *, _setValues: *, values: *, _setErrors: *, errors: *}}
 */
const useForm = ({ onSubmit, initialValues, schema }) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const validate = (newValues, allowUnknown = true) => {
    const { error, value } = schema.validate(newValues, { allowUnknown, stripUnknown: true });
    const newError = _.chain(error)
      .get("details", [])
      .transform((r, e) => {
        r[e.path] = e.message;
      }, {})
      .value();
    setErrors(newError);
    return {
      value
    };
  };

  const handleSubmit = event => {
    if (event) event.preventDefault();
    const { value } = validate(values, false);
    onSubmit(value);
  };

  const handleChange = event => {
    event.persist();
    setValues(values => {
      const newValues = { ...values, [event.target.name]: event.target.value };
      //validating
      const { value } = validate(newValues);
      return value;
    });
  };

  return {
    handleChange,
    handleSubmit,
    _setValues: setValues,
    _setErrors: setErrors,
    values,
    errors
  };
};

export default useForm;
