import Joi from '@hapi/joi'

export const contactFormSchema = Joi.object({
  name: Joi.string().min(3).max(30).required(),
  email: Joi.string().email({minDomainSegments: 2, tlds:false}).required(),
  msg_subject: Joi.string().min(5).max(50).required(),
  message: Joi.string().min(20).max(200).required()
})


export default contactFormSchema