import { styled } from "@material-ui/core";

export const Content = styled("p")({
  fontSize: "24px",
  lineHeight: "30px",
  textAlign: "center",
  fontFamily: '"OpenSans", sans-serif',
  color: "#546e7a",
  fontWeight: "400"
});

export default Content