export const teamMembers = [
  {
    name: "Cristian Mos",
    role: "Software developer",
    description:
      "Distributed systems and deployment automation enthusiast, experienced cloud based applications and services developer with a commitment to high availability.",
    photo: "/img/team/cristian.jpg"
  },
  {
    name: "Bernadett Ursu",
    role: "Software developer",
    description: `Meticulous front end developer, dedicated to produce high-quality digital experiences for both the client and their end-users.`,
    photo: "/img/team/bernadett.jpg"
  },
  {
    name: "Laurentiu Andercou",
    role: "Software developer",
    description: `Seasoned backend developer, with a focus on code quality, new technologies and open source enthusiast, adept of Domain Driven Design and Test Driven Development, strives to drive away complexity from code.`,
    photo: "/img/team/laur.jpg"
  },
  {
    name: "Rares Ilea",
    role: "Software developer",
    description: `Front end developer focused on code reusability, application speed and scalability. Passionate about React, keen to take on any new challenge.`,
    photo: "/img/team/rares.jpg"
  }
];

export default teamMembers;
