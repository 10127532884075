import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Content from "./commons/Content";
import TechnologyLogo from "./TechnologyLogo";
import SectionHeader from "./SectionHeader";
import Section from "./Section";
import technologiesList from "../constants/technology-list";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    justifyContent: "center",
    maxWidth: "25%"
  }
}));

export default function Tech() {
  const classes = useStyles();
  return (
    <Section id="technologies">
      <SectionHeader shadowText="Technologies" titleText="Our Stack" />
      <Content>
        Our mission is to deliver high-quality software and bring new technological ideas to our
        clients. We understand that technology is only a tool. But we use these tools to build
        amazing products for our clients.
      </Content>
      <Grid container spacing={6} className={classes.root}>
        {technologiesList.map((tProps, index) => (
          <Grid item md={2} sm={3} xs={6} key={index}>
            <TechnologyLogo {...tProps} />
          </Grid>
        ))}
      </Grid>
    </Section>
  );
}
