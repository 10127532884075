import React from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  role: {
    fontSize: theme.typography.body1.fontSize
  },
  description: {
    paddingTop: theme.spacing(2),
    fontSize: theme.typography.body2.fontSize,
    textAlign: "left"
  },
  card: {
    transition: "0.5s",
    width: "100%",
    minHeight: 300,
    borderRadius: 0,
    boxShadow: "0 10px 50px 0 rgba(84, 110, 122, 0.15)",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 10px 50px 0 rgba(84, 110, 122, 0.35)"
    }
  },
  media: {
    height: 0,
    paddingTop: "125%"
  }
}));

export default ({ name, role, description, photo }) => {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardMedia className={classes.media} image={photo} />
      <CardContent>
        <ListItemText
          primary={name}
          secondary={role}
          primaryTypographyProps={{ variant: "h6" }}
          classes={{
            secondary: classes.role
          }}
        />
        <ListItemText
          secondary={description}
          classes={{
            secondary: classes.description
          }}
        />
      </CardContent>
    </Card>
  );
};
