import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TeamMemberItem from "./TeamMember";
import SectionHeader from "./SectionHeader";
import Section from "./Section";
import { teamMembers } from "../constants/team-members";
import Content from "./commons/Content";

const useStyles = makeStyles(theme => ({
  teamMember: {
    padding: theme.spacing(2)
  },
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    justifyContent: "center",
    maxWidth: "100%"
  },
  single: {
    position: "relative",
    width: "100%"
  }
}));

export default function Team() {
  const classes = useStyles();
  return (
    <Section id="team" backgroundColor="transparent">
      <SectionHeader titleText="Our People" shadowText = "Team" />
      <Content>
        Our best asset is the battle tested, relentless, innovation driven team.
      </Content>
      <Grid className={classes.root} container spacing={2} alignContent="stretch">
        {teamMembers.map((tm, key) => (
          <Grid item lg={3} sm={4} xs={12} className={classes.teamMember} key={key}>
            <TeamMemberItem {...tm} />
          </Grid>
        ))}
      </Grid>
    </Section>
  );
}
