import React from "react";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Typography, CssBaseline, Box } from "@material-ui/core";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

import TabMenu from "./TabMenu";
import OurLogo from "./OurLogo";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.node.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  window: PropTypes.func
};

export default function ElevateAppBar(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <Typography component="div">
          <AppBar color="default">
            <Toolbar variant="dense">
              <Typography variant="h6" component="div">
                <Box>
                  <OurLogo />
                </Box>
              </Typography>
              <Box>
                <TabMenu />
              </Box>
            </Toolbar>
          </AppBar>
        </Typography>
      </ElevationScroll>
      <Toolbar />
    </React.Fragment>
  );
}
