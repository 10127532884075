export const technologiesList = [
  {
    src: "/img/tehnologies/Javascript.svg",
    alt: "Javascript",
    title: ""
  },
  {
    alt: "NodeJS",
    src: "/img/tehnologies/Nodejs.svg",
    title: ""
  },
  {
    alt: "ReactJS",
    src: "/img/tehnologies/React.svg",
    title: ""
  },
  {
    alt: "Redux",
    src: "/img/tehnologies/Redux.svg",
    title: ""
  },
  {
    alt: "MongoDB",
    src: "/img/tehnologies/Mongodb.svg",
    title: ""
  },
  {
    alt: "HTML5",
    src: "/img/tehnologies/Html.svg",
    title: ""
  },
  {
    alt: "Sass",
    src: "/img/tehnologies/Sass.svg",
    title: ""
  },
  {
    alt: "Kubernets",
    src: "/img/tehnologies/Kubernets.svg",
    title: ""
  },
  {
    alt: "Helm",
    src: "/img/tehnologies/helm-logo.svg",
    title: ""
  },
  {
    alt: "Docker",
    src: "/img/tehnologies/Docker.svg",
    title: ""
  },
  {
    alt: "Redis",
    src: "/img/tehnologies/Redis.svg",
    title: ""
  },
  {
    alt: "Traefik",
    src: "/img/tehnologies/traefik.svg",
    title: ""
  }
];

export default technologiesList;
